<template>
  <div>
    <b-card
      align="left"
      class="booking-technician-card side-card"
      :class="{ 'has-positions': areTherePositions }"
      header-tag="header"
      no-body
    >
      <template #header>
        <b-row>
          <b-col>
            <h5>En curso</h5>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <div class="position-wrapper" v-if="!areTherePositions && !areThereHardwarePositions">
              <div>
                <strong>¡Vaya!</strong>
              </div>
              Parece que no tienes nada en curso
            </div>
          </b-col>
        </b-row>
      </template>
      <b-card-body v-if="areTherePositions" class="position-list">
        <div v-for="position in positions" :key="position.getId">
          <BookingPositionSearchItem
            :position="position"
            @onClick="goToPosition"
            :notification="(showNotification(position.getId))"
          />
        </div>
      </b-card-body>

      <b-card-body v-if="areThereHardwarePositions" class="position-list">
        <div v-for="position in hardwarePositions" :key="position.getId">
          <HardwareBookingPositionSearchItem
            :position="position"
            @onClick="goToHardwareBookingPosition"
            :notification="(showHardwareNotification(position.getId))"
          />
        </div>
      </b-card-body>
    </b-card>

    <b-card
      align="left"
      class="booking-technician-card side-card"
      :class="{ 'has-positions': areThereNextPositions }"
      header-tag="header"
      no-body
    >
      <template #header>
        <b-row>
          <b-col>
            <h5>Próximos</h5>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <div class="position-wrapper" v-if="!areThereNextPositions && !areThereNextHardwarePositions">
              <div>
                <strong>¡Vaya!</strong>
              </div>
              Parece que no tienes nada próximamente
            </div>
          </b-col>
        </b-row>
      </template>
      <b-card-body v-if="areThereNextPositions" class="position-list">
        <div v-for="position in nextPositions" :key="position.getId">
          <BookingPositionSearchItem
            :position="position"
            @onClick="goToPosition"
            :notification="(showNotification(position.getId))"
          />
        </div>
      </b-card-body>
      <b-card-body v-if="areThereNextHardwarePositions" class="position-list">
        <div v-for="position in nextHardwarePositions" :key="position.getId">
          <HardwareBookingPositionSearchItem
            :position="position"
            @onClick="goToHardwareBookingPosition"
            :notification="(showHardwareNotification(position.getId))"
          />
        </div>
      </b-card-body>
    </b-card>
  </div>
</template>
<script>
import BookingPositionSearchItem from '@/components/BookingPosition/BookingPositionSearchItem'
import HardwareBookingPositionSearchItem from '@/components/HardwareBooking/HardwareBookingPositionSearchItem'

export default {
  name: 'BookingTechnicianAcceptedCard',
  components: { HardwareBookingPositionSearchItem, BookingPositionSearchItem },
  data () {
    return {
      positions: [],
      hardwarePositions: [],
      nextPositions: [],
      nextHardwarePositions: [],
      baseUrl: process.env.VUE_APP_IMAGE_API_URL
    }
  },
  created () {
    this.loadData()
  },
  computed: {
    areTherePositions () {
      return this.positions.length > 0
    },
    areThereNextPositions () {
      return this.nextPositions.length > 0
    },
    areThereHardwarePositions () {
      return this.hardwarePositions.length > 0
    },
    areThereNextHardwarePositions () {
      return this.nextHardwarePositions.length > 0
    }
  },
  methods: {
    loadData () {
      this.$store.dispatch('getPositionAccepted')
        .then((res) => {
          const today = new Date()
          this.positions = []
          this.nextPositions = []
          res.forEach(position => {
            const startDate = new Date(position.getEventStartAt)
            if (startDate > today) {
              this.nextPositions.push(position)
              return
            }
            this.positions.push(position)
          })
        })
      this.$store.dispatch('hardwarePosition/getHardwarePositionAccepted')
        .then((res) => {
          const today = new Date()
          this.hardwarePositions = []
          this.nextHardwarePositions = []
          res.forEach(hardwarePosition => {
            const startDate = new Date(hardwarePosition.getEventStartAt)
            if (startDate > today) {
              this.nextHardwarePositions.push(hardwarePosition)
              return
            }
            this.hardwarePositions.push(hardwarePosition)
          })
        })
    },
    goToPosition (id) {
      this.$router.push({
        name: 'positions-accepted-position',
        params: {
          id: id
        }
      })
    },
    goToHardwareBookingPosition (id) {
      this.$router.push({
        name: 'positions-accepted-hardware-position',
        params: {
          id: id
        }
      })
    },
    showNotification (id) {
      return this.$store.getters.technicianBookingNotifications.some((element) => {
        return element.bookingPosition.id === id
      })
    },
    showHardwareNotification (id) {
      return this.$store.getters.hardwareBookingNotifications.some((element) => {
        return element.bookingPosition.id === id
      })
    }
  }
}
</script>
<style lang="less">
  @import 'booking-techician.less';
  @import '../../views/pendings.less';
  .position-contents {
    .business-name {
      cursor: pointer;
    }
  }
  .btn-outline {
    outline: none;
    img {
      outline: none;
    }
  }
  .position-wrapper {
    margin-top: 1.3rem;
    margin-bottom: 6.25rem;
  }
</style>
