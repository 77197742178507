<template>
  <BookingPosition
    displayedCard="accepted"
  >
    <BookingTechnicianAcceptedCard />
  </BookingPosition>
</template>

<script>
import BookingPosition from '@/views/BookingPosition.vue'
import BookingTechnicianAcceptedCard from '@/components/BookingPosition/BookingTechnicianAcceptedCard.vue'

export default {
  name: 'BookingPositionAccepted',
  components: {
    BookingPosition,
    BookingTechnicianAcceptedCard
  }
}
</script>

<style lang="less">
</style>
